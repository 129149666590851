import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import FormInput from './FormInput';
import FormButton from './FormButton';

import newsletterIcon from './newsletter-icon.png';
import { validateText, validateEmail } from './utils';

import getStyles from './MailchimpForm.style';

const styles = getStyles();

class MailchimpForm extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      nameError: null,
      email: '',
      emailError: null,
      emailSuccess: null,
      result: null,
    };
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const { urlPathName } = this.props;
    const { firstName, email } = this.state;

    const checkName = validateText(firstName, 'your name');
    const result = validateEmail(email);

    if (!checkName.isValid) return this.setState({ nameError: checkName.text });
    if (!result) return this.setState({ emailError: 'Please enter a valid e-mail address' });

    // call the api after validation:
    try {
      // return console.log('path name here?', urlPathName);
      const res = await addToMailchimp(email, {
        POSTURL: urlPathName,
        FNAME: firstName,
      });

      if (res.result === 'error') return this.setState({ emailError: res.msg, emailSuccess: null });
      return this.setState({ emailSuccess: 'Thanks for subscribing!' });
    } catch (err) {
      this.setState({ emailError: 'Something went wrong. Please try again later.' });
    }
    // console.log('ok!')
    // const result = await addToMailchimp(this.state.email);
    // this.setState({ result });
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ firstName: val, nameError: null, emailSuccess: null });
  }

handleChange = (event) => {
  this.setState({ email: event.target.value, emailError: null, emailSuccess: null });
}

render() {
  return (
      <form onSubmit={this._handleSubmit} style={styles.wrapper}>
            <div style={styles.leftWrapper}>
                <p style={styles.leftHeader}>Subscribe to the newsletter</p>
                <p style={styles.leftDescription}>Subscribe to get my latest content by email.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={newsletterIcon} alt={'newsletter'} style={{ width: 75, marginRight: 20 }} />
                </div>
            </div>
            <div style={styles.rightWrapper}>
                <FormInput
                    type={'text'}
                    name={'first-name'}
                    label={'First name'}
                    value={this.state.firstName}
                    placeholder={'Your first name'}
                    errorText={this.state.nameError}
                    onChange={this.handleNameChange}
                />
                <FormInput
                    type={'text'}
                    name={'email'}
                    label={'E-mail'}
                    value={this.state.email}
                    placeholder={'Your email address'}
                    successText={this.state.emailSuccess}
                    errorText={this.state.emailError}
                    onChange={this.handleChange}
                />
                <div style={styles.btnWrapper}>
                    <FormButton
                      text={'Subscribe now'}
                      label="Submit"
                      type="submit"
                    />
                </div>
                <p style={styles.unsubscribeText}>I won't send you spam. <br /> Unsubscribe any time.</p>
            </div>
      </form>
  );
}
}

export default MailchimpForm;
