const getStyles = () => ({
  wrapper: {
    // margin: '15px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    borderColor: 'rgb(227, 227, 227)',
    borderRadius: 4,
    color: 'rgb(0, 0, 0)',
    fontWeight: 400,
    background: '#fff',
    fontSize: 15,
    padding: 12,
    border: '1px solid #e3e3e3',
    flex: '1 0 auto',
    lineHeight: 1.4,
    margin: 0,
  },
  inputFeedbackArea: {
    height: 20,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    color: 'red',
    margin: '2.5px 0',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  successText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14px',
    color: '#057266',
    margin: '2.5px 0',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export default getStyles;
