import React from 'react';

import getStyles from './FormInput.style';

const styles = getStyles();

const FormInput = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  errorText,
  successText,
}) => (
        <div style={styles.wrapper}>
            <input
                style={styles.input}
                type={type}
                label={label}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            <div style={styles.inputFeedbackArea}>
                <p style={styles.errorText}>{errorText || null}</p>
                <p style={styles.successText}>{successText || null}</p>
            </div>
        </div>
);

export default FormInput;
