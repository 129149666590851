const getStyles = () => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(225px,1fr))',
    boxShadow: '0 2px 15px 0 rgba(210,214,220,0.5)',
    marginBottom: 25,
  },
  leftWrapper: {
    padding: 20,
    backgroundColor: '#f9fafb',
  },
  leftHeader: {
    marginTop: 12,
    fontWeight: 700,
    fontSize: 20,
  },
  leftDescription: {
    marginBottom: 15,
  },
  rightWrapper: {
    padding: 20,
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
  unsubscribeText: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 10,
    marginBottom: 0,
  },
});

export default getStyles;
