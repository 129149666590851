// email regex
export const validateEmail = (value) =>
    /* eslint-disable-next-line */
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    )

// // utility to make first letter uppercase
// const uppercaseFirst = (val) => `${val.charAt(0).toUpperCase()}${val.slice(1)}`

export const validateText = (textValue, areaName) => {
    if (textValue.length === 0)
        return {
            isValid: false,
            text: `Please enter ${areaName}`,
        }
    // if (textValue.length < 2)
    //     return {
    //         isValid: false,
    //         text: `${uppercaseFirst(
    //             areaName
    //         )} must have at least minimum 2 characters`,
    //     }
    return {
        isValid: true,
        text: textValue,
    }
}
