import React from 'react'
import './FormButton.style.css'

const FormButton = ({
    text,
    label,
    onClick,
    style,
    type,
    variant,
    ...props
}) => (
    <button
        {...props}
        label={label}
        type={type}
        className={`form-btn`}
        style={style}
        onClick={onClick}
    >
        {text}
    </button>
)

export default FormButton
